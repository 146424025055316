import { jsxs as y, jsx as t, Fragment as K } from "react/jsx-runtime";
import { AlertGroup as Ke, Alert as je, AlertVariant as J, AlertActionCloseButton as ze, Page as Je, Modal as Ae, ModalVariant as Ye, Button as E, TextContent as Qe, Text as Q, TextVariants as Xe, Spinner as Ze, FormHelperText as le, HelperText as ae, HelperTextItem as se, Popover as et, Icon as X, FormGroup as Ee, NumberInput as tt, ValidatedOptions as V, InputGroup as ie, InputGroupItem as Z, TextInput as j, Select as Re, MenuToggle as oe, MenuToggleStatus as Fe, SelectList as Oe, SelectOption as Ve, TextInputGroup as nt, TextInputGroupMain as rt, ChipGroup as lt, Chip as at, TextInputGroupUtilities as st, Switch as it, TextArea as Pe, Title as ot, Card as ct, CardHeader as dt, CardTitle as ut, CardBody as ht, Grid as mt, GridItem as pe, PageSection as ft, JumpLinks as pt, JumpLinksItem as gt, ButtonVariant as ge, Checkbox as yt, Radio as vt, Dropdown as bt, DropdownList as Ct, DropdownItem as ye, Brand as It, Avatar as kt } from "@patternfly/react-core";
import { createContext as ce, useContext as de, useState as w, useCallback as De, useEffect as ue, useMemo as R, useRef as Ne, forwardRef as Tt, useId as xt, Fragment as qe } from "react";
import { useTranslation as he } from "react-i18next";
import wt from "keycloak-js";
import { ExclamationCircleIcon as St, HelpIcon as ve, EyeIcon as At, EyeSlashIcon as Et, TimesIcon as Rt, CubeIcon as Ft, PaypalIcon as Ot, InstagramIcon as Vt, BitbucketIcon as Pt, MicrosoftIcon as Dt, TwitterIcon as Nt, StackOverflowIcon as qt, OpenshiftIcon as Lt, LinkedinIcon as Mt, GoogleIcon as Ut, GitlabIcon as Bt, FacebookSquareIcon as _t, GithubIcon as Ht, MinusCircleIcon as $t, PlusCircleIcon as Gt, EllipsisVIcon as Wt } from "@patternfly/react-icons";
import { useFormContext as z, Controller as M, useController as me, FormProvider as Kt, useWatch as jt } from "react-hook-form";
import { get as _ } from "lodash-es";
import { Select as zt, SelectOption as Jt, PageHeader as Yt, PageHeaderTools as Qt, PageHeaderToolsGroup as Xt, PageHeaderToolsItem as Y } from "@patternfly/react-core/deprecated";
import W from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as Zt } from "@patternfly/react-styles";
import './main.css';const Le = ce(void 0), er = () => de(Le), en = ({ children: e }) => {
  const [n, r] = w([]), l = (i) => {
    r((c) => c.filter((o) => o.id !== i));
  }, a = (i, c = J.success, o) => {
    r([
      {
        id: Math.random() * 100,
        message: i,
        variant: c,
        description: o
      },
      ...n
    ]);
  }, s = (i) => {
    a(i, J.danger);
  };
  return /* @__PURE__ */ y(Le.Provider, { value: { addAlert: a, addError: s }, children: [
    /* @__PURE__ */ t(Ke, { isToast: !0, "data-testid": "alerts", children: n.map(({ id: i, variant: c, message: o, description: u }) => /* @__PURE__ */ t(
      je,
      {
        isLiveRegion: !0,
        variant: J[c],
        variantLabel: "",
        title: o,
        actionClose: /* @__PURE__ */ t(
          ze,
          {
            title: o,
            onClose: () => l(i)
          }
        ),
        timeout: !0,
        onTimeout: () => l(i),
        children: u && /* @__PURE__ */ t("p", { children: u })
      },
      i
    )) }),
    e
  ] });
}, tn = (e) => {
  const { t: n } = he(), r = e.error, l = nn(r);
  function a() {
    location.href = location.origin + location.pathname;
  }
  return /* @__PURE__ */ t(Je, { children: /* @__PURE__ */ t(
    Ae,
    {
      variant: Ye.small,
      title: n("somethingWentWrong"),
      titleIconVariant: "danger",
      showClose: !1,
      isOpen: !0,
      actions: [
        /* @__PURE__ */ t(E, { variant: "primary", onClick: a, children: n("tryAgain") }, "tryAgain")
      ],
      children: /* @__PURE__ */ y(Qe, { children: [
        /* @__PURE__ */ t(Q, { children: n("somethingWentWrongDescription") }),
        l && /* @__PURE__ */ t(Q, { component: Xe.small, children: l })
      ] })
    }
  ) });
};
function nn(e) {
  return typeof e == "string" ? e : e instanceof Error ? e.message : null;
}
function rn(e, n) {
  const r = ce(n);
  return r.displayName = e, r;
}
function ln(e) {
  return e != null;
}
function an(e) {
  const n = de(e);
  if (ln(n))
    return n;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function sn(e, n, r) {
  const [l, a] = w(
    () => e.getItem(n) ?? r
  ), s = De((i) => {
    a(i), e.setItem(n, i);
  }, []);
  return ue(() => {
    a(e.getItem(n) ?? r), window.addEventListener("storage", i);
    function i(c) {
      c.storageArea === e && (c.key === null || c.key === n) && a(c.newValue ?? r);
    }
    return () => window.removeEventListener("storage", i);
  }, [e, n]), [l, s];
}
function on(e, n, r) {
  const l = R(
    () => JSON.stringify(r),
    [r]
  ), [a, s] = sn(
    e,
    n,
    l
  ), i = R(() => JSON.parse(a), [a]), c = De(
    (o) => s(JSON.stringify(o)),
    []
  );
  return [i, c];
}
const Me = rn(
  "HelpContext",
  void 0
), cn = () => an(Me), dn = ({ children: e }) => {
  const [n, r] = on(localStorage, "helpEnabled", !0);
  function l() {
    r(!n);
  }
  return /* @__PURE__ */ t(Me.Provider, { value: { enabled: n, toggleHelp: l }, children: e });
}, un = () => ce(void 0);
let ee;
const tr = () => {
  const e = de(ee);
  if (!e)
    throw Error(
      "no environment provider in the hierarchy make sure to add the provider"
    );
  return e;
}, nr = ({
  environment: e,
  children: n
}) => {
  ee = un();
  const r = Ne(!1), [l, a] = w(!1), [s, i] = w(), c = R(() => {
    const o = new wt({
      url: "authServerUrl" in e ? e.authServerUrl : e.authUrl,
      realm: e.realm,
      clientId: e.clientId
    });
    return o.onAuthLogout = () => o.login(), o;
  }, [e]);
  return ue(() => {
    if (r.current)
      return;
    c.init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      responseMode: "query"
    }).then(() => a(!0)).catch((u) => i(u)), r.current = !0;
  }, [c]), s ? /* @__PURE__ */ t(tn, { error: s }) : l ? /* @__PURE__ */ t(ee.Provider, { value: { environment: e, keycloak: c }, children: /* @__PURE__ */ t(en, { children: /* @__PURE__ */ t(dn, { children: n }) }) }) : /* @__PURE__ */ t(Ze, {});
}, be = "master", Ce = new URLSearchParams(window.location.search).get("realm") || location.pathname.match("/realms/(.*?)/account")?.[1], hn = {
  authUrl: "http://localhost:8180",
  authServerUrl: "http://localhost:8180",
  baseUrl: `http://localhost:8180/realms/${Ce ?? be}/account/`,
  realm: Ce ?? be,
  clientId: "security-admin-console-v2",
  resourceUrl: "http://localhost:8080",
  logo: "/logo.svg",
  logoUrl: "/",
  locale: "en",
  consoleBaseUrl: "/admin/master/console/",
  masterRealm: "master",
  resourceVersion: "unknown",
  features: {
    isRegistrationEmailAsUsername: !1,
    isEditUserNameAllowed: !0,
    isInternationalizationEnabled: !0,
    isLinkedAccountsEnabled: !0,
    isEventsEnabled: !0,
    isMyResourcesEnabled: !0,
    isTotpConfigured: !0,
    deleteAccountAllowed: !0,
    updateEmailFeatureEnabled: !0,
    updateEmailActionEnabled: !0,
    isViewGroupsEnabled: !0,
    isOid4VciEnabled: !1
  }
}, rr = {
  ...hn,
  ...mn()
};
function mn() {
  const e = document.getElementById("environment");
  let n = {};
  try {
    e?.textContent && (n = JSON.parse(e.textContent));
  } catch {
    console.error("Unable to parse environment variables.");
  }
  return n;
}
const lr = ({
  modalTitle: e,
  continueLabel: n,
  cancelLabel: r,
  buttonTitle: l,
  isDisabled: a,
  buttonVariant: s,
  buttonTestRole: i,
  onContinue: c,
  component: o = E,
  children: u,
  ...m
}) => {
  const [g, h] = w(!1);
  return /* @__PURE__ */ y(K, { children: [
    /* @__PURE__ */ t(
      o,
      {
        variant: s,
        onClick: () => h(!0),
        isDisabled: a,
        "data-testrole": i,
        children: l
      }
    ),
    /* @__PURE__ */ t(
      Ae,
      {
        variant: "small",
        ...m,
        title: e,
        isOpen: g,
        onClose: () => h(!1),
        actions: [
          /* @__PURE__ */ t(
            E,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                h(!1), c();
              },
              children: n
            },
            "confirm"
          ),
          /* @__PURE__ */ t(
            E,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => h(!1),
              children: r
            },
            "cancel"
          )
        ],
        children: u
      }
    )
  ] });
}, Ue = ({ message: e, ...n }) => /* @__PURE__ */ t(le, { ...n, children: /* @__PURE__ */ t(ae, { children: /* @__PURE__ */ t(se, { icon: /* @__PURE__ */ t(St, {}), variant: "error", children: e }) }) }), Be = ({
  helpText: e,
  fieldLabelId: n,
  noVerticalAlign: r = !0,
  unWrap: l = !1
}) => {
  const { enabled: a } = cn();
  return a ? /* @__PURE__ */ t(et, { bodyContent: e, children: /* @__PURE__ */ y(K, { children: [
    !l && /* @__PURE__ */ t(
      "button",
      {
        "data-testid": `help-label-${n}`,
        "aria-label": n,
        onClick: (s) => s.preventDefault(),
        className: "pf-v5-c-form__group-label-help",
        children: /* @__PURE__ */ t(X, { isInline: r, children: /* @__PURE__ */ t(ve, {}) })
      }
    ),
    l && /* @__PURE__ */ t(X, { isInline: r, children: /* @__PURE__ */ t(ve, {}) })
  ] }) }) : null;
}, N = ({
  name: e,
  label: n,
  labelIcon: r,
  error: l,
  children: a,
  ...s
}) => /* @__PURE__ */ y(
  Ee,
  {
    label: n || e,
    fieldId: e,
    labelIcon: r ? /* @__PURE__ */ t(Be, { helpText: r, fieldLabelId: e }) : void 0,
    ...s,
    children: [
      a,
      l && /* @__PURE__ */ t(Ue, { "data-testid": `${e}-helper`, message: l.message })
    ]
  }
), ar = ({
  name: e,
  label: n,
  controller: r,
  labelIcon: l,
  ...a
}) => {
  const {
    control: s,
    formState: { errors: i }
  } = z();
  return /* @__PURE__ */ t(
    N,
    {
      name: e,
      label: n,
      isRequired: r.rules?.required === !0,
      error: i[e],
      labelIcon: l,
      children: /* @__PURE__ */ t(
        M,
        {
          ...r,
          name: e,
          control: s,
          render: ({ field: c }) => {
            const o = !!r.rules?.required, u = r.rules?.min, m = c.value === 0 ? r.defaultValue : c.value, g = (h) => c.onChange(u ? Math.max(h, Number(u)) : h);
            return /* @__PURE__ */ t(
              tt,
              {
                ...a,
                id: e,
                value: m,
                validated: i[e] ? V.error : V.default,
                required: o,
                min: Number(u),
                max: Number(r.rules?.max),
                onPlus: () => g(m + 1),
                onMinus: () => g(m - 1),
                onChange: (h) => {
                  const d = Number(h.currentTarget.value);
                  g(isNaN(d) ? r.defaultValue : d);
                }
              }
            );
          }
        }
      )
    }
  );
}, fn = ({
  hasReveal: e = !0,
  innerRef: n,
  ...r
}) => {
  const { t: l } = he(), [a, s] = w(!0);
  return /* @__PURE__ */ y(ie, { children: [
    /* @__PURE__ */ t(Z, { isFill: !0, children: /* @__PURE__ */ t(
      j,
      {
        ...r,
        type: a ? "password" : "text",
        ref: n
      }
    ) }),
    e && /* @__PURE__ */ t(
      E,
      {
        variant: "control",
        "aria-label": l("showPassword"),
        onClick: () => s(!a),
        children: a ? /* @__PURE__ */ t(At, {}) : /* @__PURE__ */ t(Et, {})
      }
    )
  ] });
}, _e = Tt(
  (e, n) => /* @__PURE__ */ t(fn, { ...e, innerRef: n })
);
_e.displayName = "PasswordInput";
const sr = (e) => {
  const { labelIcon: n, ...r } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: s, fieldState: i } = me({
    ...e,
    defaultValue: a
  });
  return /* @__PURE__ */ y(
    N,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: i.error,
      children: [
        /* @__PURE__ */ t(
          _e,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e.name,
            validated: i.error ? V.error : V.default,
            isDisabled: e.isDisabled,
            ...r,
            ...s
          }
        ),
        e.helperText && /* @__PURE__ */ t(le, { children: /* @__PURE__ */ t(ae, { children: /* @__PURE__ */ t(se, { children: e.helperText }) }) })
      ]
    }
  );
}, pn = ({
  id: e,
  name: n,
  label: r,
  options: l,
  controller: a,
  labelIcon: s,
  ...i
}) => {
  const {
    control: c,
    formState: { errors: o }
  } = z(), [u, m] = w(!1), g = () => l.map((h) => /* @__PURE__ */ t(Ve, { value: A(h), children: fe(h) ? h : h.value }, A(h)));
  return /* @__PURE__ */ t(
    N,
    {
      name: n,
      label: r,
      isRequired: !!a.rules?.required,
      error: _(o, n),
      labelIcon: s,
      children: /* @__PURE__ */ t(
        M,
        {
          ...a,
          name: n,
          control: c,
          render: ({ field: { onChange: h, value: d } }) => /* @__PURE__ */ t(
            Re,
            {
              ...i,
              onClick: () => m(!u),
              selected: B(l) ? l.filter(
                (p) => Array.isArray(d) ? d.includes(p.key) : d === p.key
              ).map((p) => p.value) : d,
              toggle: (p) => /* @__PURE__ */ t(
                oe,
                {
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  ref: p,
                  onClick: () => m(!u),
                  isExpanded: u,
                  isFullWidth: !0,
                  status: _(o, n) ? Fe.danger : void 0,
                  "aria-label": "toggle",
                  children: B(l) ? l.find((v) => v.key === d)?.value : d
                }
              ),
              onSelect: (p, v) => {
                if (p?.stopPropagation(), Array.isArray(d)) {
                  const I = v?.toString(), F = A(I);
                  d.includes(A) ? h(d.filter((q) => q !== F)) : h([...d, I]);
                } else
                  h(v), m(!1);
              },
              isOpen: u,
              children: /* @__PURE__ */ t(Oe, { children: g() })
            }
          )
        }
      )
    }
  );
}, L = (e) => fe(e) ? e : e.value, gn = ({
  id: e,
  name: n,
  label: r,
  options: l,
  controller: a,
  labelIcon: s,
  placeholderText: i,
  onFilter: c,
  variant: o,
  ...u
}) => {
  const {
    control: m,
    formState: { errors: g }
  } = z(), [h, d] = w(!1), [p, v] = w(""), [I, F] = w(0), q = Ne(), k = l.filter(
    (f) => L(f).toLowerCase().startsWith(p.toLowerCase())
  ), T = R(
    () => k.map((f, b) => /* @__PURE__ */ t(
      Ve,
      {
        value: A(f),
        isFocused: I === b,
        children: L(f)
      },
      A(f)
    )),
    [I, k]
  ), G = (f, b) => {
    const x = k[I];
    switch (d(!0), f.key) {
      case "Enter": {
        f.preventDefault(), o !== te.typeaheadMulti && v(L(x)), b.onChange(
          Array.isArray(b.value) ? [...b.value, L(x)] : L(x)
        ), d(!1), F(0);
        break;
      }
      case "Tab":
      case "Escape": {
        d(!1), b.onChange(void 0);
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        f.preventDefault();
        let C = 0;
        f.key === "ArrowUp" && (I === 0 ? C = l.length - 1 : C = I - 1), f.key === "ArrowDown" && (I === l.length - 1 ? C = 0 : C = I + 1), F(C);
        break;
      }
    }
  };
  return /* @__PURE__ */ t(
    N,
    {
      name: n,
      label: r,
      isRequired: !!a.rules?.required,
      error: _(g, n),
      labelIcon: s,
      children: /* @__PURE__ */ t(
        M,
        {
          ...a,
          name: n,
          control: m,
          render: ({ field: f }) => /* @__PURE__ */ t(
            Re,
            {
              ...u,
              onClick: () => d(!h),
              selected: B(l) ? l.filter(
                (b) => Array.isArray(f.value) ? f.value.includes(b.key) : f.value === b.key
              ).map((b) => b.value) : f.value,
              toggle: (b) => /* @__PURE__ */ t(
                oe,
                {
                  ref: b,
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  variant: "typeahead",
                  onClick: () => d(!h),
                  isExpanded: h,
                  isFullWidth: !0,
                  status: _(g, n) ? Fe.danger : void 0,
                  children: /* @__PURE__ */ y(nt, { isPlain: !0, children: [
                    /* @__PURE__ */ t(
                      rt,
                      {
                        placeholder: i,
                        value: p,
                        onClick: () => d(!h),
                        onChange: (x, C) => {
                          v(C), c?.(C);
                        },
                        onKeyDown: (x) => G(x, f),
                        autoComplete: "off",
                        innerRef: q,
                        role: "combobox",
                        isExpanded: h,
                        "aria-controls": "select-typeahead-listbox",
                        children: o === te.typeaheadMulti && Array.isArray(f.value) && /* @__PURE__ */ t(lt, { "aria-label": "Current selections", children: f.value.map(
                          (x, C) => /* @__PURE__ */ t(
                            at,
                            {
                              onClick: (O) => {
                                O.stopPropagation(), f.onChange(
                                  f.value.filter(
                                    (U) => U !== A(x)
                                  )
                                );
                              },
                              children: B(l) ? l.find((O) => x === O.key)?.value : L(x)
                            },
                            C
                          )
                        ) })
                      }
                    ),
                    /* @__PURE__ */ t(st, { children: !!p && /* @__PURE__ */ t(
                      E,
                      {
                        variant: "plain",
                        onClick: () => {
                          f.onChange(void 0), v(""), q?.current?.focus();
                        },
                        "aria-label": "Clear input value",
                        children: /* @__PURE__ */ t(Rt, { "aria-hidden": !0 })
                      }
                    ) })
                  ] })
                }
              ),
              onSelect: (b, x) => {
                b?.stopPropagation();
                const C = x?.toString();
                if (Array.isArray(f.value)) {
                  const O = A(C);
                  f.value.includes(A) ? f.onChange(
                    f.value.filter((U) => U !== O)
                  ) : f.onChange([...f.value, C]);
                } else {
                  const O = B(l) ? l.find((U) => U.key === C)?.value : C;
                  f.onChange(O), v(O || ""), d(!1);
                }
              },
              isOpen: h,
              children: /* @__PURE__ */ t(Oe, { children: T })
            }
          )
        }
      )
    }
  );
};
var te = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(te || {});
const B = (e) => typeof e[0] != "string", fe = (e) => typeof e == "string", A = (e) => fe(e) ? e : e.key, yn = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(pn, { ...n }) : /* @__PURE__ */ t(gn, { ...n, variant: e }), ir = ({
  labelOn: e,
  stringify: n,
  defaultValue: r,
  labelIcon: l,
  ...a
}) => {
  const i = r ?? (n ? "false" : !1), { control: c } = z();
  return /* @__PURE__ */ t(
    N,
    {
      hasNoPaddingTop: !0,
      name: a.name,
      isRequired: a.rules?.required === !0,
      label: a.label,
      labelIcon: l,
      children: /* @__PURE__ */ t(
        M,
        {
          control: c,
          name: a.name,
          defaultValue: i,
          render: ({ field: { onChange: o, value: u } }) => /* @__PURE__ */ t(
            it,
            {
              ...a,
              id: a.name,
              "data-testid": a.name,
              label: e,
              isChecked: n ? u === "true" : u,
              onChange: (m, g) => {
                const h = n ? g.toString() : g;
                a.onChange?.(m, g), o(h);
              }
            }
          )
        }
      )
    }
  );
}, or = (e) => {
  const n = !!e.rules?.required, r = e.defaultValue ?? "", { field: l, fieldState: a } = me({
    ...e,
    defaultValue: r
  });
  return /* @__PURE__ */ t(
    N,
    {
      isRequired: n,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: a.error,
      children: /* @__PURE__ */ t(
        Pe,
        {
          isRequired: n,
          id: e.name,
          "data-testid": e.name,
          validated: a.error ? V.error : V.default,
          isDisabled: e.isDisabled,
          ...l
        }
      )
    }
  );
}, cr = (e) => {
  const { labelIcon: n, ...r } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: s, fieldState: i } = me({
    ...e,
    defaultValue: a
  });
  return /* @__PURE__ */ y(
    N,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: i.error,
      children: [
        /* @__PURE__ */ t(
          j,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e.name,
            validated: i.error ? V.error : V.default,
            isDisabled: e.isDisabled,
            ...r,
            ...s
          }
        ),
        e.helperText && /* @__PURE__ */ t(le, { children: /* @__PURE__ */ t(ae, { children: /* @__PURE__ */ t(se, { children: e.helperText }) }) })
      ]
    }
  );
}, vn = Pe, dr = ({ icon: e }) => {
  const n = bn(e);
  return /* @__PURE__ */ t(X, { size: "lg", children: /* @__PURE__ */ t(n, { alt: e }) });
};
function bn(e) {
  switch (e) {
    case "github":
      return Ht;
    case "facebook":
      return _t;
    case "gitlab":
      return Bt;
    case "google":
      return Ut;
    case "linkedin":
    case "linkedin-openid-connect":
      return Mt;
    case "openshift-v3":
    case "openshift-v4":
      return Lt;
    case "stackoverflow":
      return qt;
    case "twitter":
      return Nt;
    case "microsoft":
      return Dt;
    case "bitbucket":
      return Pt;
    case "instagram":
      return Vt;
    case "paypal":
      return Ot;
    default:
      return Ft;
  }
}
const Cn = "_title_180i0_2", In = {
  title: Cn
}, He = ({
  id: e,
  title: n,
  headingLevel: r = "h1",
  size: l = "xl",
  ...a
}) => /* @__PURE__ */ t(
  ot,
  {
    headingLevel: r,
    size: l,
    className: In.title,
    id: e,
    tabIndex: 0,
    ...a,
    children: n
  }
), kn = ({
  title: e,
  children: n,
  scrollId: r,
  className: l
}) => {
  const a = xt();
  return /* @__PURE__ */ y(ct, { id: a, className: l, isFlat: !0, children: [
    /* @__PURE__ */ t(dt, { className: "kc-form-panel__header", children: /* @__PURE__ */ t(ut, { tabIndex: 0, children: /* @__PURE__ */ t(He, { id: r, title: e }) }) }),
    /* @__PURE__ */ t(ht, { className: "kc-form-panel__body", children: n })
  ] });
}, Tn = (e) => {
  const { title: n, children: r, scrollId: l, ...a } = e;
  return /* @__PURE__ */ t("section", { ...a, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ y(K, { children: [
    /* @__PURE__ */ t(He, { id: l, title: n }),
    r
  ] }) });
}, xn = "_panel_1cdve_1", wn = "_sticky_1cdve_5", Ie = {
  panel: xn,
  sticky: wn
}, Sn = "kc-main-content-page-container", ke = (e) => e.replace(/\s+/g, "-"), An = ({
  label: e,
  sections: n,
  borders: r = !1,
  ...l
}) => {
  const a = R(
    () => n.filter(({ isHidden: s }) => !s),
    [n]
  );
  return /* @__PURE__ */ y(mt, { hasGutter: !0, ...l, children: [
    /* @__PURE__ */ t(pe, { md: 8, sm: 12, children: a.map(({ title: s, panel: i }) => {
      const c = ke(s.toLowerCase());
      return /* @__PURE__ */ t(qe, { children: r ? /* @__PURE__ */ t(
        kn,
        {
          scrollId: c,
          title: s,
          className: Ie.panel,
          children: i
        }
      ) : /* @__PURE__ */ t(Tn, { scrollId: c, title: s, children: i }) }, s);
    }) }),
    /* @__PURE__ */ t(pe, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ t(ft, { className: Ie.sticky, children: /* @__PURE__ */ t(
      pt,
      {
        isVertical: !0,
        scrollableSelector: `#${Sn}`,
        label: e,
        offset: 100,
        children: a.map(({ title: s }) => {
          const i = ke(s.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ t(
              gt,
              {
                href: `#${i}`,
                "data-testid": `jump-link-${i}`,
                children: s
              },
              s
            )
          );
        })
      }
    ) }) })
  ] });
}, En = (e, n, r) => (e.isValid || r) && (e.isDirty || n) && !e.isLoading && !e.isValidating && !e.isSubmitting, ur = ({
  formState: e,
  isDisabled: n = !1,
  allowInvalid: r = !1,
  allowNonDirty: l = !1,
  children: a,
  ...s
}) => /* @__PURE__ */ t(
  E,
  {
    variant: "primary",
    isDisabled: e && !En(e, l, r) || n,
    ...s,
    type: "submit",
    children: a
  }
), Rn = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, Fn = ({
  t: e,
  form: n,
  supportedLocales: r,
  currentLocale: l
}) => {
  const a = R(
    () => r.map((s) => ({
      key: s,
      value: e(`locale_${s}`, Rn(s) ?? s)
    })).sort((s, i) => s.value.localeCompare(i.value, l)),
    [r, l, e]
  );
  return a.length ? /* @__PURE__ */ t(Kt, { ...n, children: /* @__PURE__ */ t(
    yn,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: a,
      variant: a.length >= 10 ? "typeahead" : "single"
    }
  ) }) : null;
}, $e = (e) => e?.includes("${"), Ge = (e) => e.substring(2, e.length - 1), D = (e, n, r) => ($e(n) ? e(Ge(n)) : n) || r, ne = (e, n) => D(e, n.displayName, n.name), On = ["username", "firstName", "lastName", "email"], We = (e) => e && On.includes(e), P = (e) => `${We(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, hr = (e) => e.replaceAll(".", "🍺"), mr = (e) => e.replaceAll("🍺", ".");
function fr(e, n, r) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((l) => {
    const a = Object.assign(
      {},
      l.params?.map((s) => r($e(s.toString()) ? Ge(s) : s))
    );
    n(P(l.field), {
      message: r(l.errorMessage, {
        ...a,
        defaultValue: l.errorMessage || l.field
      }),
      type: "server"
    });
  });
}
function H({
  required: e,
  validators: n
}) {
  return e || Vn(n);
}
function Vn(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function pr(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: n } = e;
  return Te(n) ? !0 : typeof n != "object" || n === null || !("errors" in n) || !Array.isArray(n.errors) ? !1 : n.errors.every(Te);
}
function Te(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const $ = ({
  t: e,
  form: n,
  attribute: r,
  renderer: l,
  children: a
}) => {
  const s = D(
    e,
    r.annotations?.inputHelperTextBefore
  ), {
    formState: { errors: i }
  } = n, c = l?.(r), o = _(i, P(r.name));
  return /* @__PURE__ */ y(
    Ee,
    {
      label: ne(e, r) || "",
      fieldId: r.name,
      isRequired: H(r),
      labelIcon: s ? /* @__PURE__ */ t(Be, { helpText: s, fieldLabelId: r.name }) : void 0,
      children: [
        c ? /* @__PURE__ */ y(ie, { children: [
          a,
          c
        ] }) : a,
        o && /* @__PURE__ */ t(
          Ue,
          {
            "data-testid": `${r.name}-helper`,
            message: o.message
          }
        )
      ]
    },
    r.name
  );
}, Pn = ({
  t: e,
  form: n,
  attribute: r,
  renderer: l,
  ...a
}) => /* @__PURE__ */ t($, { t: e, form: n, attribute: r, renderer: l, children: /* @__PURE__ */ t(
  Dn,
  {
    t: e,
    form: n,
    "aria-label": ne(e, r),
    name: P(r.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: ne(e, r)
    }),
    ...a
  }
) }), Dn = ({
  t: e,
  name: n,
  inputType: r,
  form: l,
  addButtonLabel: a,
  isDisabled: s = !1,
  defaultValue: i,
  id: c,
  ...o
}) => {
  const { register: u, setValue: m, control: g } = l, h = jt({
    name: n,
    control: g,
    defaultValue: i || ""
  }), d = R(() => Array.isArray(h) && h.length !== 0 ? h : i || [""], [h]), p = (k) => {
    F([...d.slice(0, k), ...d.slice(k + 1)]);
  }, v = () => {
    F([...d, ""]);
  }, I = (k, T) => {
    F([...d.slice(0, k), T, ...d.slice(k + 1)]);
  }, F = (k) => {
    const T = k.flatMap((G) => G);
    m(n, T, {
      shouldDirty: !0
    });
  }, q = r.startsWith("html") ? r.substring(6) : "text";
  return ue(() => {
    u(n);
  }, [u]), /* @__PURE__ */ t("div", { id: c, children: d.map((k, T) => /* @__PURE__ */ y(qe, { children: [
    /* @__PURE__ */ y(ie, { children: [
      /* @__PURE__ */ t(Z, { isFill: !0, children: /* @__PURE__ */ t(
        j,
        {
          "data-testid": n + T,
          onChange: (G, f) => I(T, f),
          name: `${n}.${T}.value`,
          value: k,
          isDisabled: s,
          type: q,
          ...o
        }
      ) }),
      /* @__PURE__ */ t(Z, { children: /* @__PURE__ */ t(
        E,
        {
          "data-testid": "remove" + T,
          variant: ge.link,
          onClick: () => p(T),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: d.length === 1 || s,
          children: /* @__PURE__ */ t($t, {})
        }
      ) })
    ] }),
    T === d.length - 1 && /* @__PURE__ */ y(
      E,
      {
        variant: ge.link,
        onClick: v,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !k || s,
        children: [
          /* @__PURE__ */ t(Gt, {}),
          " ",
          e(a || "add")
        ]
      }
    )
  ] }, T)) });
}, xe = (e) => {
  const { form: n, inputType: r, attribute: l } = e, a = H(l), s = r.startsWith("multiselect"), i = s ? yt : vt, c = l.validators?.options?.options || [], o = l.annotations?.inputOptionLabels || {};
  return /* @__PURE__ */ t($, { ...e, children: /* @__PURE__ */ t(
    M,
    {
      name: P(l.name),
      control: n.control,
      defaultValue: "",
      render: ({ field: u }) => /* @__PURE__ */ t(K, { children: c.map((m) => /* @__PURE__ */ t(
        i,
        {
          id: m,
          "data-testid": m,
          label: D(e.t, o[m], m),
          value: m,
          isChecked: u.value.includes(m),
          onChange: () => {
            s ? u.value.includes(m) ? u.onChange(
              u.value.filter((g) => g !== m)
            ) : u.onChange([...u.value, m]) : u.onChange([m]);
          },
          readOnly: l.readOnly,
          isRequired: a
        },
        m
      )) })
    }
  ) });
}, we = (e) => {
  const { t: n, form: r, inputType: l, attribute: a } = e, [s, i] = w(!1), c = H(a), o = l === "multiselect", u = (d, p) => {
    o ? p.value.includes(d) ? p.onChange(p.value.filter((v) => v !== d)) : p.onChange([...p.value, d]) : p.onChange(d);
  }, m = a.validators?.options?.options || [], g = a.annotations?.inputOptionLabels || {}, h = (d) => D(e.t, g[d], d);
  return /* @__PURE__ */ t($, { ...e, children: /* @__PURE__ */ t(
    M,
    {
      name: P(a.name),
      defaultValue: "",
      control: r.control,
      render: ({ field: d }) => /* @__PURE__ */ t(
        zt,
        {
          toggleId: a.name,
          onToggle: (p, v) => i(v),
          onSelect: (p, v) => {
            const I = v.toString();
            u(I, d), Array.isArray(d.value) || i(!1);
          },
          selections: d.value ? d.value : o ? [] : n("choose"),
          variant: o ? "typeaheadmulti" : m.length >= 10 ? "typeahead" : "single",
          "aria-label": n("selectOne"),
          isOpen: s,
          isDisabled: a.readOnly,
          required: c,
          children: ["", ...m].map((p) => /* @__PURE__ */ t(
            Jt,
            {
              selected: d.value === p,
              value: p,
              children: p ? h(p) : n("choose")
            },
            p
          ))
        }
      )
    }
  ) });
}, Nn = (e) => {
  const { form: n, attribute: r } = e, l = H(r);
  return /* @__PURE__ */ t($, { ...e, children: /* @__PURE__ */ t(
    vn,
    {
      id: r.name,
      "data-testid": r.name,
      ...n.register(P(r.name)),
      cols: r.annotations?.inputTypeCols,
      rows: r.annotations?.inputTypeRows,
      readOnly: r.readOnly,
      isRequired: l
    }
  ) });
}, S = (e) => {
  const { form: n, inputType: r, attribute: l } = e, a = H(l), s = r.startsWith("html") ? r.substring(6) : "text";
  return /* @__PURE__ */ t($, { ...e, children: /* @__PURE__ */ t(
    j,
    {
      id: l.name,
      "data-testid": l.name,
      type: s,
      placeholder: D(
        e.t,
        l.annotations?.inputTypePlaceholder
      ),
      readOnly: l.readOnly,
      isRequired: a,
      ...n.register(P(l.name))
    }
  ) });
}, re = {
  text: S,
  textarea: Nn,
  select: we,
  "select-radiobuttons": xe,
  multiselect: we,
  "multiselect-checkboxes": xe,
  "html5-email": S,
  "html5-tel": S,
  "html5-url": S,
  "html5-number": S,
  "html5-range": S,
  "html5-datetime-local": S,
  "html5-date": S,
  "html5-month": S,
  "html5-time": S,
  "multi-input": Pn
}, gr = ({
  t: e,
  form: n,
  userProfileMetadata: r,
  supportedLocales: l,
  currentLocale: a,
  hideReadOnly: s = !1,
  renderer: i
}) => {
  const c = R(() => {
    if (!r.attributes)
      return [];
    const o = s ? r.attributes.filter(({ readOnly: u }) => !u) : r.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...r.groups ?? []
    ].map((u) => ({
      group: u,
      attributes: o.filter(
        (m) => m.group === u.name
      )
    }));
  }, [
    s,
    r.groups,
    r.attributes
  ]);
  return c.length === 0 ? null : /* @__PURE__ */ t(
    An,
    {
      label: e("jumpToSection"),
      sections: c.filter((o) => o.attributes.length > 0).map(({ group: o, attributes: u }) => ({
        title: D(e, o.displayHeader, o.name) || e("general"),
        panel: /* @__PURE__ */ y("div", { className: "pf-v5-c-form", children: [
          o.displayDescription && /* @__PURE__ */ t(Q, { className: "pf-v5-u-pb-lg", children: D(e, o.displayDescription, "") }),
          u.map((m) => /* @__PURE__ */ t(
            qn,
            {
              t: e,
              form: n,
              supportedLocales: l,
              currentLocale: a,
              renderer: i,
              attribute: m
            },
            m.name
          ))
        ] })
      }))
    }
  );
}, qn = ({
  t: e,
  form: n,
  renderer: r,
  supportedLocales: l,
  currentLocale: a,
  attribute: s
}) => {
  const i = n.watch(
    P(s.name)
  ), c = R(() => Mn(s), [s]), o = s.multivalued || Bn(i) ? re["multi-input"] : re[c];
  return s.name === "locale" ? /* @__PURE__ */ t(
    Fn,
    {
      form: n,
      supportedLocales: l,
      currentLocale: a,
      t: e,
      attribute: s
    }
  ) : /* @__PURE__ */ t(
    o,
    {
      t: e,
      form: n,
      inputType: c,
      attribute: s,
      renderer: r
    }
  );
}, Ln = "text";
function Mn(e) {
  if (We(e.name))
    return "text";
  const n = e.annotations?.inputType;
  return Un(n) ? n : Ln;
}
const Un = (e) => typeof e == "string" && e in re, Bn = (e) => Array.isArray(e) && e.length > 1, _n = ({
  className: e = "",
  border: n,
  size: r = "md"
}) => /* @__PURE__ */ y(
  "svg",
  {
    className: Zt(
      W.avatar,
      W.modifiers[r],
      n === "light" && W.modifiers.light,
      n === "dark" && W.modifiers.dark,
      e
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ t(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ t(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ t(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ t(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ t("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ y("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), Se = ({
  isKebab: e = !1,
  title: n,
  dropDownItems: r,
  ...l
}) => {
  const [a, s] = w(!1);
  return /* @__PURE__ */ t(
    bt,
    {
      ...l,
      popperProps: {
        position: "right"
      },
      toggle: (i) => /* @__PURE__ */ t(
        oe,
        {
          "data-testid": `${l["data-testid"]}-toggle`,
          ref: i,
          onClick: () => s(!a),
          isExpanded: a,
          variant: e ? "plain" : "default",
          children: e ? /* @__PURE__ */ t(Wt, {}) : n
        }
      ),
      isOpen: a,
      children: /* @__PURE__ */ t(Ct, { children: r })
    }
  );
};
function Hn(e, n) {
  if (!e)
    return n("unknownUser");
  const r = e.given_name, l = e.family_name, a = e.preferred_username;
  return r && l ? n("fullName", { givenName: r, familyName: l }) : r || l || a || n("unknownUser");
}
const yr = ({
  keycloak: e,
  brand: { href: n, ...r },
  avatar: l,
  features: {
    hasLogout: a = !0,
    hasManageAccount: s = !0,
    hasUsername: i = !0
  } = {},
  kebabDropdownItems: c,
  dropdownItems: o = [],
  toolbarItems: u,
  ...m
}) => {
  const { t: g } = he(), h = [];
  s && h.push(
    /* @__PURE__ */ t(
      ye,
      {
        onClick: () => e.accountManagement(),
        children: g("manageAccount")
      },
      "manageAccount"
    )
  ), a && h.push(
    /* @__PURE__ */ t(ye, { onClick: () => e.logout(), children: g("signOut") }, "signOut")
  );
  const d = e.idTokenParsed?.picture;
  return /* @__PURE__ */ t(
    Yt,
    {
      ...m,
      logo: /* @__PURE__ */ t(It, { ...r }),
      logoProps: { href: n },
      headerTools: /* @__PURE__ */ y(Qt, { children: [
        /* @__PURE__ */ y(Xt, { children: [
          /* @__PURE__ */ t(
            Y,
            {
              visibility: {
                md: "hidden"
              },
              children: /* @__PURE__ */ t(
                Se,
                {
                  "data-testid": "options-kebab",
                  isKebab: !0,
                  dropDownItems: [
                    ...c || o,
                    h
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ t(Y, { children: u }),
          /* @__PURE__ */ t(
            Y,
            {
              visibility: {
                default: "hidden",
                md: "visible"
              },
              children: /* @__PURE__ */ t(
                Se,
                {
                  "data-testid": "options",
                  dropDownItems: [...o, h],
                  title: i ? Hn(e.idTokenParsed, g) : void 0
                }
              )
            }
          )
        ] }),
        d || l?.src ? /* @__PURE__ */ t(kt, { src: d, alt: g("avatar"), ...l }) : /* @__PURE__ */ t(_n, { ...l })
      ] })
    }
  );
};
export {
  en as AlertProvider,
  lr as ContinueCancelModal,
  tn as ErrorPage,
  Ue as FormErrorText,
  kn as FormPanel,
  ur as FormSubmitButton,
  dn as Help,
  Be as HelpItem,
  dr as IconMapper,
  yr as KeycloakMasthead,
  nr as KeycloakProvider,
  vn as KeycloakTextArea,
  ar as NumberControl,
  sr as PasswordControl,
  _e as PasswordInput,
  An as ScrollForm,
  yn as SelectControl,
  te as SelectVariant,
  ir as SwitchControl,
  or as TextAreaControl,
  cr as TextControl,
  gr as UserProfileFields,
  hr as beerify,
  rn as createNamedContext,
  mr as debeerify,
  rr as environment,
  ln as isDefined,
  pr as isUserProfileError,
  D as label,
  Sn as mainPageContentId,
  fr as setUserProfileServerError,
  er as useAlerts,
  tr as useEnvironment,
  cn as useHelp,
  an as useRequiredContext,
  on as useStoredState
};
